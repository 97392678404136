.loan-agreement {
  border: 0.5px solid lightgray;
  height: 320px;
  width: 100% !important;
  font-family: "Arial";
  position: relative;
  padding: 15px;
  margin-bottom: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  background: #ffffff !important;
  -webkit-overflow-scrolling: touch;
  border: 1px solid rgba(184, 185, 186, 0.5);

  #adoptElectronicSignatureModal div .ModalBody {
    padding: 5px 5px !important;
    font-size: 1rem !important;
  }

  #agreeElectronicSignatureModal,
  #adoptElectronicSignatureModal {
    top: 5% !important;

    .ModalHeader {
      font-size: 1em !important;
      font-weight: bold;
    }

    p {
      font-size: 0.7em !important;
    }

    label {
      font-size: 0.8em !important;
      color: #000 !important;
      font-weight: 700 !important;
    }

    button {
      padding: 12px !important;
      background-color: #337ab7 !important;
      color: #000 !important;
      font-size: 1.2em !important;

      hover {
        background-color: #337ab7 !important;
        color: white !important;
      }
    }

    #adopt {
      padding: 12px !important;
      background-color: #337ab7 !important;
      color: #000 !important;
      font-size: 0.5rem !important;

      hover {
        background-color: #337ab7 !important;
        color: white !important;
      }
    }

    #cancel {
      padding: 12px !important;
      background-color: lightgray !important;
      color: #000 !important;
      font-size: 0.5rem !important;

      hover {
        background-color: #337ab7 !important;
        color: white !important;
      }
    }

    .ModalBody {
      .cursive-font {
        font-size: 1.2rem !important;
        font-weight: 700 !important;
      }

      p {
        font-size: 0.7rem !important;
        color: #000 !important;
      }

      * {
        font-size: 0.7em !important;
      }
    }
  }

  #adoptElectronicSignatureModal {
    .ModalBody {
      p {
        font-size: 0.5rem !important;
        color: #000 !important;
      }
    }
  }

  .emphasized {
    overflow-y: hidden !important;
  }

  #darkLayer {
    height: 100% !important;
  }

  * {
    color: #000;

    .CenterAlign.Bold.BigFont {
      padding-top: 20px;
    }

    img {
      height: 40px;
    }

    h1 {
      font-size: 20px !important;
    }

    h2 {
      font-size: 18px !important;
    }

    table {
      font-size: 1.5em !important;

      .BigFont {
        font-size: 1em !important;
      }
    }
  }
}

.pd-10 {
  padding: 10px;
}
