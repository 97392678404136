.redeem-page {
  padding: 20px;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.section-head {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.section-head h5 {
  color: #7fc241;
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 5px;
}

.section-head h1 {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 20px;
}

.rewards-point-summary {
  font-size: 1.2em;
  font-weight: 400;
  margin-bottom: 20px;
}

.point-summary-value {
  font-weight: bold;
  font-size: 1.5em;
}

.star {
  width: 20px;
  height: 20px;
  background-image: url("../../assets/images/star.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin: 30px auto 10px auto;
}

.main-title {
  font-weight: bold;
  color: #838588;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.sub-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 40px;
  color: #838588;
}

.grid {
  display: flex;
  justify-content: center;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
  gap: 10px 15px;
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}

.rewards-disclaimer {
  display: flex;
  justify-content: center;
}

.reward-image {
  width: 250px;
  height: 150px;
  border-radius: 8px;
  object-fit: cover;
}

.disclaimers {
  margin: 10px 14%;
  font-size: 10px;
  color: #555;
  text-align: left;
}

.selected-card {
  padding: 10px;
  background-color: lightgray;
  color: #838588;
}

.brand {
  margin: 0 0 10px 0;
  font-size: 10px;
  font-weight: 500;
}
